import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/reusable/PageHeader';
import Phones from '../components/reusable/Phones';


const NotFoundPage = () => {
 return (
  <Layout>
    <SEO title="Not found" />
    <PageHeader title="Not found" />
    <div className="container" style={{minHeight: '15rem'}}>
      It looks like something went wrong.
      <br />
      A page with this address doesn't exist.
    </div>
    <Phones />
  </Layout>
  )
}

export default NotFoundPage;
